import { ApolloLink, HttpLink } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { RetryLink } from '@apollo/client/link/retry'
import { throwServerError } from '@apollo/client/link/utils'
import cookieStorage from 'cookie-storage'
import logger from 'logger'


export type FetchFunction = (url: string, options: FetchOptions) => Promise<Response>

export type FetchOptions = RequestInit & {
  operationName?: string
  timeout?: number
}

export type ModifyOptions = (options: FetchOptions) => FetchOptions
export type ModifyResponse = (response) => Response

type CreateHttpLinkOptions = {
  name: string
  fetch: FetchFunction // node fetch or window fetch
  uri: string
  modifyOptions?: ModifyOptions
  modifyResponse?: ModifyResponse
  timeout?: number
}

export const createHttpLink = (options: CreateHttpLinkOptions) => {
  const { name = 'GraphQL', fetch, uri, modifyOptions, modifyResponse, timeout: defaultTimeout } = options

  // helps to add timeout to fetch
  const fetchWithTimeout: FetchFunction = (uri, options: FetchOptions) => {
    const timeout = options.timeout

    // 0. skip everything if signals aren't supported
    if (!options.signal) {
      return fetch(uri, options)
    }

    // we need override apollo signal, because apollo aborts controller after a watch
    return new Promise((resolve, reject) => {
      // 1. apollo provides its own signal to stop irrelevant queries, but they don't give any errors and just stop the request
      const apolloSignal = options.signal
      // 2. so we create our own AbortController and AbortSignal to cancel the request and throw an error
      const controller = new AbortController()
      let isResolved = false

      // 3. we abort it on timeout and reject the query result
      const timeoutId = timeout > 0 ? setTimeout(() => {
        controller.abort()
        reject(new Error(`[${name}]: ${options.operationName} time is out`))
      }, timeout) : null

      // 4. but we still need to keep in mind that apollo can cancel the query
      if (apolloSignal.aborted) {
        controller.abort()
      }
      else {
        // 4.1 if Apollo cancels the query
        const apolloSignalHandler = () => {
          // if request is resolved, but apollo cancels the query we don't abort original signal
          if (!isResolved) {
            controller.abort()
          }
          clearTimeout(timeoutId)
        }

        apolloSignal.addEventListener('abort', apolloSignalHandler, {
          once: true,
          // this can free up memory by removing dangling references to the controller
          // (part of the DOM specification and works in Node 16+ (Node 14????))
          signal: controller.signal,
        })
      }

      // 5. replace original signal to our
      options.signal = controller.signal

      fetch(uri, options)
        .finally(() => {
          isResolved = true
          // reset timeout if we got some result
          clearTimeout(timeoutId)
        })
        // pass result and error
        .then(resolve, reject)
    })
  }

  const customFetch: FetchFunction = (uri, options: FetchOptions) => {
    const { operationName } = options

    const modifiedOptions = typeof modifyOptions === 'function' ? modifyOptions(options) : options

    logger.debug(`[${name}]: ${operationName}`)
    const start = Date.now()

    return fetchWithTimeout(uri, modifiedOptions).then((response) => {
      const responseTime = Date.now() - start
      let result = response

      const traceId = response.headers.get('x-trace-id')
      const status = response.status
      const addition = traceId ? ` x-trace-id: ${traceId}` : ''

      logger.debug(`[${name}]: ${operationName} completed ${responseTime}ms ${status !== 200 ? status : ''}${addition}`)

      if (typeof modifyResponse === 'function') {
        result = modifyResponse(result)
      }

      if (status > 300) {
        throwServerError(response, result, `Response not successful: Received status code ${status}${addition}`)
      }

      return result
    })
  }

  return new HttpLink({
    uri: (operation) => {
      const { operationName } = operation
      const { searchParams, timeout, fetchOptions } = operation.getContext()

      // required for logging
      operation.setContext({
        fetchOptions: {
          ...fetchOptions,
          operationName: operation.operationName,
          timeout: typeof timeout === 'number' ? timeout : defaultTimeout,
        },
      })

      const search = searchParams ? `&${searchParams}` : ''

      return `${uri}/graphql?opname=${operationName}${search}`
    },
    credentials: 'include',
    fetch: customFetch,
  })
}

type CreateWSLinkOptions = {
  uri: string
  connectionParams: () => Record<string, string> | Promise<Record<string, string>>
}

export const createWSLink = (options: CreateWSLinkOptions) => {
  const { uri, connectionParams } = options

  // ATTN new graphql-ws doesn't work with our backend
  return new WebSocketLink({
    uri: uri,
    options: {
      reconnect: true,
      lazy: true,
      inactivityTimeout: 5 * 1000, // 5s
      connectionParams,
    },
  })
}

// provides retry functionality
export const createRetryLink = (maxAttempts: number = 2) => {
  return new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: maxAttempts,
      retryIf: (error, operation) => {
        // disable retry for mutations
        const definition = getMainDefinition(operation.query)
        if (definition.kind === 'OperationDefinition' && definition.operation === 'mutation') {
          return false
        }

        if (!error) {
          return false
        }

        const { statusCode } = error

        // 401 - UNAUTHENTICATED. Backend can return this error if user's session is expired
        return statusCode === undefined || statusCode === 401
      },
    },
  })
}

// add csrfToken support
export const createCFRFTokenLink = () => (
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      const csrfToken = cookieStorage.getItem('csrfToken')

      if (csrfToken) {
        return {
          headers: {
            ...headers,
            'x-csrf-token': csrfToken,
          },
        }
      }

      return null
    })

    return forward(operation).map((response) => {
      const { response: { headers } } = operation.getContext()

      const csrfToken = headers ? headers.get('x-csrf-token') : null

      if (csrfToken) {
        cookieStorage.setSessionItem('csrfToken', csrfToken)
      }

      return response
    })
  })
)

export const createTraceIdLink = () => (
  new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const { response: { headers } } = operation.getContext()
      const traceId = headers?.get('x-trace-id') || null

      // for all mutations, add traceId field to each custom error
      const definition = getMainDefinition(operation.query)
      if (definition.kind === 'OperationDefinition' && definition.operation === 'mutation') {
        if (response.data) {
          Object.keys(response.data).forEach((key) => {
            if (response.data[key]?.error) {
              response.data[key].error.traceId = traceId
            }
          })
        }
      }

      return response
    })
  })
)

// add auth API key
// export const createAPIKeyLink = (apiKey: string) => (
//   new ApolloLink((operation, forward) => {
//     operation.setContext(({ headers }) => {
//       return {
//         headers: {
//           ...headers,
//           'Authorization': `Bearer ${apiKey}`,
//         },
//       }
//     })
//
//     return forward(operation)
//   })
// )

